import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: '',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: null,
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: true,
		isWidgetDisabled: false,
		isMonitor: true, // специальное поле для Монитора, т.к. много хардкода
	},
	feedback: false,
	hasNeedUseRoutingWithoutCityId: true,
};

